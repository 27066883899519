<template>
  <div class="administrator-role-page-wrapper">
    <el-card class="administrator-role-page">
      <el-row slot="header">
        <el-button type="primary" size="mini" style="font-size: 14px" :disabled="true">新增角色</el-button>
      </el-row>
      <el-table :data="roleList" :height="tHeight">
        <el-table-column label="序号" prop="id" align="center" width="80"></el-table-column>
        <el-table-column label="角色ID" prop="roleId" align="center" width="120"></el-table-column>
        <el-table-column label="角色昵称" prop="roleName"></el-table-column>
        <el-table-column label="创建时间" prop="createTime">
          <template slot-scope="scope">
            {{ $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss') }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="mini" icon="el-icon-setting" @click="handleSetRole(scope.row.roleId)">设置权限</el-button>
            <el-button type="text" size="mini" icon="el-icon-edit-outline">编辑</el-button>
            <el-button type="text" size="mini" icon="el-icon-delete" style="color: #f40">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-row class="pagination-bar">
        <el-pagination background layout="total,prev, pager, next" :current-page="currentPage" :page-size="pageSize" @current-change="handleCurrentChange" :total="totalNum"></el-pagination>
      </el-row>
    </el-card>
    <el-drawer :visible.sync="drawer" direction="rtl" :with-header="false">
      <el-tabs type="border-card" v-model="active" @tab-click="handleClick">
        <el-tab-pane label="角色菜单" name="first">
          <el-form>
            <el-form-item>
              <el-button size="mini" type="primary" @click="setupRoleByRoleId()">确定</el-button>
            </el-form-item>
          </el-form>
          <tree-transfer :title="title" :openAll="false" :from_data="selectedMenu" :to_data="menuTree" :defaultProps="{ label: 'label' }" @add-btn="add" @remove-btn="remove" :mode="mode" height="540px" filter openAll></tree-transfer>
        </el-tab-pane>
        <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
      </el-tabs>
    </el-drawer>
  </div>
</template>

<script>
import treeTransfer from 'el-tree-transfer';
import { getRoleManager, getRoleInfoDetail } from '@/api/role';
import { getRoleByRoleId, setRoleByRoleId } from '@/api/user';
import { flatten } from '@/router/comparisonTableRouter';
import sleep from '@/utils/sleep';
import statusInclude from '@/utils/statusCode';
export default {
  name: 'AdministratorRole',
  components: {
    treeTransfer,
  },
  data() {
    return {
      roleId: '',
      tHeight: 600,
      drawer: false,
      active: 'first',
      mode: 'transfer',
      currentPage: 1,
      pageSize: 13,
      totalNum: 18,
      roleList: [],
      selectedCurrentMenu: [],
      selectedMenu: [],
      menuTree: [],
      title: ['可分配菜单权限', '已拥有菜单权限'],
      defaultProps: {
        children: 'children',
        label: 'label',
      },
    };
  },
  created() {
    this.getHeight();
    //请求用户权限
  },
  mounted() {
    this.getSourceSize(200).then(size => {
      this.pageSize = size;
      this.getRoleManager(this.currentPage, this.pageSize);
    });
  },
  methods: {
    getHeight() {
      this.tHeight = document.getElementsByTagName('body')[0].offsetHeight - this.$store.state.settings.offset;
    },
    handleCurrentChange() {},
    //表格高度请求所需要请求的数据size
    async getSourceSize(time) {
      await sleep(time);
      return Math.ceil(parseInt(document.getElementsByClassName('el-table__body-wrapper')[0].style.height) / 51);
    },
    async getRoleManager(currentPage = 1, pageSize = 13, menuName = '') {
      const { code, data, reason } = await getRoleManager(currentPage, pageSize, menuName);
      if (statusInclude(code)) {
        const { records, total } = data;
        this.roleList = records;
        this.totalNum = total;
      }
    },

    //整合菜单权限数据
    async getRoleInformationByUID(uId) {
      const { code, data: menus, reason } = await getRoleByRoleId(uId);
      const isExistPMenus = [];
      const isNotExistPMenus = [];
      this.selectedCurrentMenu = [];
      if (!statusInclude(code)) return this.$message.error(`请求角色权限失败${reason}`);
      menus.forEach((pmenu, index) => {
        const { isExist, menuName: label, parentmenuId: id, chidMenus } = pmenu;
        const isExistMenus = [];
        const isNotExistMenus = [];
        if (!chidMenus) return;
        chidMenus.forEach(menu => {
          const { isExist, menuId: id, menuName: label, parentmenuId: pid } = menu;
          if (isExist) {
            this.selectedCurrentMenu.push(id);
            isExistMenus.push({ pid, id, label, children: [] });
          } else {
            isNotExistMenus.push({ pid, id, label, children: [] });
          }
        });
        if (isExist && !isNotExistMenus.length) {
          this.selectedCurrentMenu.push(id);
          isExistPMenus.push({ pid: index, id, label, children: isExistMenus });
        } else if (isExist && isExistMenus.length && isNotExistMenus.length) {
          this.selectedCurrentMenu.push(id);
          isExistPMenus.push({ pid: index, id, label, children: isExistMenus });
          isNotExistPMenus.push({ pid: index, id, label, children: isNotExistMenus });
        } else {
          isNotExistPMenus.push({ pid: index, id, label, children: isNotExistMenus });
        }
      });
      this.selectedMenu = isNotExistPMenus;
      this.menuTree = isExistPMenus;
    },
    async setupRoleByRoleId() {
      const { code, reason } = await setRoleByRoleId({ roleId: this.roleId, menuIds: this.selectedCurrentMenu });
      if (!statusInclude(code)) return this.$message.error(`权限设置失败：${reason}`);
      this.$message({ type: `权限设置成功`, message: reason });
    },
    //监听treeComponent 增加数据事件
    add(fromData, toData, obj) {
      console.log(obj);
      const { keys } = obj;
      this.selectedCurrentMenu = this.selectedCurrentMenu.concat(keys.filter(menu => !this.selectedCurrentMenu.includes(menu)));
    },
    //监听treeComponent 移除数据事件
    remove(fromData, toData, obj) {
      console.log(toData);
      this.selectedCurrentMenu = flatten(toData, 'children', ['id']).reduce((pre, cur) => {
        pre.push(cur.id);
        return pre;
      }, []);
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    handleClick() {},
    handleSetRole(roleId) {
      this.roleId = roleId;
      this.drawer = true;
      this.getRoleInformationByUID(roleId);
    },
  },
};
</script>

<style lang="scss" scoped>
.administrator-role-page-wrapper {
  ::v-deep .el-card__body {
    height: calc(100vh - 185px);
    position: relative;
  }
  ::v-deep .el-drawer__wrapper {
    .el-drawer__container {
      .el-drawer {
        .el-drawer__body {
          .el-tabs {
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
